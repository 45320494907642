<template>
  <li class="nav-item nav-search">

    <!-- Icon -->
    <a
      href="javascript:void(0)"
      class="nav-link nav-link-search"
      @click="showSearchBar = true"
    >
      <feather-icon
        icon="SearchIcon"
        size="21"
      />
    </a>

    <!-- Input -->
    <div
      class="search-input"
      :class="{'open': showSearchBar}"
    >
      <div class="search-input-icon">
        <feather-icon icon="SearchIcon" />
      </div>
      <!-- <input type="text" placeholder="Explore Vuexy...." class="form-control-input"> -->
      <!-- @keyup.esc="escPressed" -->
      <!-- @keyup.enter="suggestionSelected" -->
      <b-form-input
        v-if="showSearchBar"
        v-model="searchQuery"
        :placeholder="$t('label_search')"
        autofocus
        autocomplete="off"
        @keyup.esc="showSearchBar = false;resetsearchQuery()"
        @keypress.enter="refresh"
      />

<!--      @keyup.enter="refreshList = true"-->
      <!--@keyup.up="increaseIndex(false)"-->
      <!--@keyup.down="increaseIndex"-->
      <!--@keyup.esc="showSearchBar = false; resetsearchQuery()"-->
      <!--@keyup.enter="suggestionSelected; "-->
      <!--@blur.stop="showSearchBar = false; resetsearchQuery()"-->
      <div
        class="search-input-close"
        @click="showSearchBar = false; resetsearchQuery()"
      >
        <feather-icon icon="XIcon" />
      </div>

      <infinity-list
              @close-bar="showSearchBar = false; searchQuery= '';$store.commit('app/TOGGLE_OVERLAY', false)"
              :searchQuery="searchQuery"
              :class="{'show': searchQuery}"
              url="/client_setting/search_data"
              :refresh-list.sync="refreshList"
      ></infinity-list>

    </div>
  </li>
</template>

<script>
import {
  BFormInput, BLink, BImg, BAvatar, BMedia, BMediaBody
} from 'bootstrap-vue'

import VuePerfectScrollbar from 'vue-perfect-scrollbar'

import infinityList from './infinityList'

export default {
  components: {
    BFormInput,
    BLink,
    BImg,
    BAvatar,
    VuePerfectScrollbar,
      infinityList,
    BMedia, BMediaBody
  },
    data() {
        return {
            refreshList: false,
            showSearchBar: false,
            // perfectScrollbarSettings: {
            //     maxScrollbarLength: 60,
            // },

            filteredData: {},
            searchQuery: '',

            // searchParams:{
            //     perPage:50,
            //     currentPage:1,
            //
            // }
        }
    },
    methods:{
        refresh() {
          this.refreshList = true
        },
        // suggestionSelected(grpName, suggestion){
        //     // If parameter is not provided => Use current selected
        //     if (!suggestion) {
        //         // If currentSelected value is -1 => No value/item is selected (Prevent Errors)
        //         if (this.currentSelected !== -1) {
        //             /* eslint-disable no-use-before-define, no-param-reassign */
        //             const [grpIndex, itemIndex] = this.currentSelected.split('.')
        //             grpName = Object.keys(this.filteredData)[grpIndex]
        //             suggestion = this.filteredData[grpName][itemIndex]
        //             /* eslint-enable */
        //         }
        //     }
        //     if (grpName === 'pages') this.$router.push(suggestion.route).catch(() => {})
        //     // eslint-disable-next-line no-use-before-define
        //     this.resetsearchQuery();
        //     this.showSearchBar = false;
        // },

        resetsearchQuery(){
            this.searchQuery = '';
            this.$store.commit('app/TOGGLE_OVERLAY', false);
        },

        // handleSearchQueryUpdate(val){
        //     if (val === ''){
        //         this.filteredData = {}
        //     } else {
        //         this.async('get', '/client_setting/search_data', {
        //             params:{
        //                 // $search_type = $this->input->post('search_type');
        //                 search: val,
        //                 length: this.searchParams.perPage,
        //                 start: (this.searchParams.currentPage - 1) * this.searchParams.perPage,
        //                 // page: this.searchParams.currentPage,
        //             }
        //
        //         }, function (resp) {
        //             this.filteredData = this.prepareData(resp.data);
        //         });
        //     }
        // },

        increaseIndex (val = true){

            // // If there's no matching items
            // if (!Object.values(filteredData.value).some(grpItems => grpItems.length)) return
            //
            // const [grpIndex, itemIndex] = currentSelected.value.split('.')
            //
            // const grpArr = Object.entries(filteredData.value)
            // const activeGrpTotalItems = grpArr[grpIndex][1].length
            //
            // if (val) {
            //     // If active item is not of last item in grp
            //     if (activeGrpTotalItems - 1 > itemIndex) {
            //         currentSelected.value = `${grpIndex}.${Number(itemIndex) + 1}`
            //
            //         // If active item grp is not last in grp list
            //     } else if (grpIndex < grpArr.length - 1) {
            //         for (let i = Number(grpIndex) + 1; i < grpArr.length; i++) {
            //             // If navigating group have items => Then move in that group
            //             if (grpArr[i][1].length > 0) {
            //                 currentSelected.value = `${Number(i)}.0`
            //                 break
            //             }
            //         }
            //     }
            // } else {
            //     // If active item is not of first item in grp
            //     if (Number(itemIndex)) {
            //         currentSelected.value = `${grpIndex}.${Number(itemIndex) - 1}`
            //
            //         // If active item grp  is not first in grp list
            //     } else if (Number(grpIndex)) {
            //         for (let i = Number(grpIndex) - 1; i >= 0; i--) {
            //             // If navigating group have items => Then move in that group
            //             if (grpArr[i][1].length > 0) {
            //                 currentSelected.value = `${i}.${grpArr[i][1].length - 1}`
            //                 break
            //             }
            //         }
            //     }
            // }
        }
    },
    watch:{
        searchQuery:function(newVal){
            // this.handleSearchQueryUpdate(newVal);
            if(newVal) {
                this.refreshList = true;
                this.$store.commit('app/TOGGLE_OVERLAY', Boolean(newVal))
            }

        },
        filteredData(val){
            if (!Object.values(val).some(obj => obj.length)) {
                this.currentSelected = -1
            } else {
                // Auto Select first item if it's not item-404
                let grpIndex = null;

                for (const [index, grpSuggestions] of Object.values(val).entries()) {
                    if (grpSuggestions.length) {
                        grpIndex = index
                        break
                    }
                }

                if (grpIndex !== null) this.currentSelected = `${grpIndex}.0`
            }
        }
    },
}
</script>

<template>
    <b-nav-item-dropdown
            right
            toggle-class="d-flex align-items-center dropdown-user-link"
            class="dropdown-user"
    >
        <template #button-content>
            <div class="d-sm-flex d-none user-nav">
                <p class="user-name font-weight-bolder mb-0">
                    {{ auth_user.name }}
                </p>
                <span class="user-status">{{ $t('label_' + auth_user.role) }}</span>
            </div>
            <b-avatar
                    size="40"
                    :src="$domain + (auth_user.profile_picture)"
                    variant="light-primary"
                    badge
                    class="badge-minimal"
                    badge-variant="success"
            >

            </b-avatar>
        </template>

        <template>
            <b-dropdown-item

                    :to="{ name: 'user_settings'}"
                    link-class="d-flex align-items-center"
            >
                <feather-icon
                        size="16"
                        icon="Edit3Icon"
                        class="mr-50"
                />
                <span>{{$t('label_edit_profile').ucFirst()}}</span>
            </b-dropdown-item>
        </template>

        <template v-if="auth_user.role == 'agent' && $can('view', 'agent.payment')">
            <b-dropdown-item

                    :to="{ name: 'agent_payments'}"
                    link-class="d-flex align-items-center"
            >
                <feather-icon
                        size="16"
                        icon="CreditCardIcon"
                        class="mr-50"
                />
                <span>{{$t('label_payments')}}</span>
            </b-dropdown-item>
        </template>

        <template>
            <b-dropdown-item

                    :to="{ name: 'user_change_password'}"
                    link-class="d-flex align-items-center"
            >
                <feather-icon
                        size="16"
                        icon="UnlockIcon"
                        class="mr-50"
                />
                <span>{{$t('label_change_password').ucFirst()}}</span>
            </b-dropdown-item>
        </template>
<!--        <template>-->
<!--            <b-dropdown-item-->
<!--                    v-if="$can('edit', 'settings.core')"-->
<!--                    :to="{ name: 'user_email_settings'}"-->
<!--                    link-class="d-flex align-items-center"-->
<!--            >-->
<!--                <feather-icon-->
<!--                        size="16"-->
<!--                        icon="MailIcon"-->
<!--                        class="mr-50"-->
<!--                />-->
<!--                <span>{{$t('label_email_settings').ucFirst()}}</span>-->
<!--            </b-dropdown-item>-->
<!--        </template>-->

        <template>
            <b-dropdown-item
                    :to="{ name: 'setting_google_calendar'}"
                    link-class="d-flex align-items-center"
            >
                <feather-icon
                        size="16"
                        icon="CalendarIcon"
                        class="mr-50"
                />
                <span>{{$t('label_google_calendar_init')}}</span>
            </b-dropdown-item>
        </template>

        <b-dropdown-item
                link-class="d-flex align-items-center"
                @click="logout"
        >
            <feather-icon
                    size="16"
                    icon="LogOutIcon"
                    class="mr-50"
            />
            <span>Logout</span>
        </b-dropdown-item></b-nav-item-dropdown>
</template>

<script>
    import {
        BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
    } from 'bootstrap-vue'
    import { initialAbility } from '@/libs/acl/config'
    import useJwt from '@/auth/jwt/useJwt'
    import { avatarText } from '@core/utils/filter'

    export default {
        components: {
            BNavItemDropdown,
            BDropdownItem,
            BDropdownDivider,
            BAvatar,
        },
        data() {
            return {
                auth_user: this.authUser(),
                avatarText,
            }
        },
        // created() {
        //
        // },
        methods: {
            logout() {
                this.confirmAction(this.$t('label_logout'), this.$t('label_logout_confirm'),'warning',(result) => {

                    this.$ability.update(initialAbility)

                    // Redirect to login page
                    useJwt.clearStorageAndRedirect(true);
                });

            },
        },
    }
</script>

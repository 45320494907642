<template>

    <b-nav-item-dropdown
            class="dropdown-notification mr-25"
            menu-class="dropdown-menu-media"
            right
    >
        <!--@shown="getFilter()"-->
        <template #button-content>
            <feather-icon
                    :badge="total"
                    badge-classes="bg-danger"
                    class=""
                    icon="BellIcon"
                    size="21"
            />
        </template>

        <!-- Header -->
        <li class="dropdown-menu-header">
            <div class="dropdown-header d-flex">
                <h4 class="notification-title mb-0 mr-auto">
                    <!--{{$t('label_notifications')}}-->
                    <b-avatar
                            v-b-tooltip.hover.top.d200="$t(item.label)"
                            class="mr-50 cursor-pointer"
                            v-for="item in noteTypes"
                            :variant="`light-primary`"
                            size="30"

                    >
                        <!--:icon="icon"-->
                        <feather-icon
                                @click.prevent="filterData.type = item.type;"
                                size="16"
                                :icon="item.icon"
                        />
                    </b-avatar>
                </h4>
                <b-badge
                        pill
                        variant="light-primary"
                        class="d-flex align-items-center"
                >
                    {{sortedTotal}}
                </b-badge>
            </div>
        </li>

        <infinity-list
                url="/user_notifications"
                :refresh-list.sync="refreshList"
                :queryParams="{status: 1, type: filterData.type}"
                tagname="li"
                count="10"
                height="200px"
                class="user-note-dropdown"
                @data-fetched="updateNotesCount($event)"
        >
            <template #item="{item, ind}">
                <b-media tag="div" no-body v-if="item.type_name !== 'google_api'">
                    <b-media-body>

                        <b-link class="note-link d-block px-1 "
                                @click="solveRedirect(item)"
                                :key="ind"

                        >
                            <b-media class="">
                                <template #aside>
                                    <!--:src="item.avatar"-->
                                    <!--:text="item.avatar"-->
                                    <!--:variant="item.type"-->
                                    <b-avatar
                                            :variant="`light-primary`"
                                            size="35"
                                    >
                                        <feather-icon
                                                size="19"
                                                :icon="item.icon"
                                        />
                                    </b-avatar>
                                </template>
                                <p class="media-heading">
                                    <span class="">
                                      {{ item.title }}({{solveSourceName(item)}})
                                    </span>
                                </p>
                                <div class="">
                                    <small class="item-text ">{{ formatDate(item.postedtime, 'DD.MM.YYYY HH:mm',
                                        'unix')}}
                                    </small>
                                </div>
                            </b-media>
                        </b-link>

                    </b-media-body>
                </b-media>
              <span v-else></span>
            </template>
        </infinity-list>

        <!-- Cart Footer -->

        <li class="dropdown-menu-footer">
            <b-button size="sm"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-primary"
                      block
                      @click="readAll();close()"
            >{{$t('label_mark_all_as_read')}}
            </b-button>
            <b-button size="sm"
                      :to="{name: 'user_notifications'}"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      block
            >{{$t('label_read_all_notifications')}}
            </b-button>
        </li>
    </b-nav-item-dropdown>
</template>

<script>
    import {
        BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox, BMediaBody, VBTooltip
    } from 'bootstrap-vue'
    import infinityList from '@/views/components/infinityList'
    import Ripple from 'vue-ripple-directive'

    export default {

        components: {
            BNavItemDropdown,
            BBadge,
            BMedia,
            BLink,
            BAvatar,
            infinityList,
            BButton,
            BFormCheckbox, BMediaBody,
        },
        directives: {
            Ripple,
            'b-tooltip': VBTooltip,
        },
        data() {
            return {
                // firstNotificationInit: true,
                notifications: [],
                refreshList: false,
                noteTypes: [
                    {icon: 'CheckSquareIcon', type: 5, label: 'label_tasks'},
                    {icon: 'UserPlusIcon', type: 1, label: 'label_users'},
                    {icon: 'MailIcon', type: 4, label: 'label_mail'},
                    {icon: 'FileIcon', type: 2, label: 'label_documents'},
                    {icon: 'MessageCircleIcon', type: 3, label: 'label_comments'},
                ],
                total: 0,
                sortedTotal: 0,
                filterData: {type: null}
            }
        },

        watch: {
            filterData: {
                handler: function (newVal, old) {
                    this.refreshList = true;
                },
                deep: true
            },
        },

        methods: {
            readAll() {
                this.async('get', '/user_notifications/allread', {}, function (resp) {
                    this.refreshList = true;
                });

            },
            solveRedirect(item) {
                let source_table = item.source_table;  let module_id = item.item_id;

                if(item[item.type_name]){

                    let params = {};
                    let routeName = {};
                    switch (source_table) {
                        case 'com_agreement_tasks':
                            if(item.deal){
                                routeName = 'deal_tabs';
                                params.id = item.deal.id;
                            } else {
                                params.id = module_id;
                                routeName = 'agreement_tabs';
                            }

                            params.alias = 'task';

                            break;
                        case 'com_agreement_notes':
                            routeName = 'agreement_tabs';
                            params.alias = 'note';
                            params.id = module_id;
                            break;
                        case 'com_agreement_task_comments':
                            routeName = 'deal_task_details';
                            params.id = module_id;
                            break;
                        case 'com_agreements':
                            routeName = 'deal-details';
                            params.id = module_id;
                            break;
                        case 'com_mails':

                            routeName = 'mails_' + item.mail.mail_type;
                            // params.id = module_id;
                            break;
                    }

                    this.close();


                    this.readNotification(item.id, true);
                    this.$router.push({name: routeName, params: params, meta: {rerendering:true,navActiveLink: routeName+(params.alias?params.alias:'')+ params.id}}).catch((e) => {});

                } else {
                    this.readNotification(item.id, false);
                    return false;
                }

            },
            solveSourceName(item){
                let name = '';
                let source = item[item.type_name];
                if(source){

                    switch(item.type_name){
                        case 'deal_task':
                        case 'mail':
                        case 'deal_note':
                        case 'deal_task_comment':
                        case 'deal':
                            if(item.deal?.number){
                                name = item.deal.number;
                            } else {
                                if(item.agreement){
                                    name = item.agreement.deal_number;
                                } else {
                                    name = this.$t('label_agreement_register');
                                }
                            }
                            break;
                    }
                } else {
                    if(item.type_name ==  'google_api') {
                        name = item.message;
                    } else {
                        name = '-- '+this.$t('label_activity_procurations_delete')+' --';
                    }
                }
                return name;
            },

            close() {
                setTimeout(() => {
                    this.filterData.type = null;

                    document.getElementsByTagName('body')[0].click();
                }, 100);
            },

            readNotification(id, needRefresh) {
                this.async('get', '/user_notifications/read/' + id, {params: {}}, function (resp) {
                    if(needRefresh){
                        this.refreshList = true;
                    }

                });
            },

            updateNotesCount(data){
                if(!this.filterData.type) {
                    this.total = data.total;
                    this.sortedTotal = data.total;
                } else {
                    this.sortedTotal = data.total;
                }

            }
        },


    }
</script>


